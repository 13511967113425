import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppConstant } from "../../../../../../../Assests/AppConstant";
import { sagaActions } from "../../../../../../../../sagas/sagaActions";

import Popup from "../../../../../../Common/Popup/Popup";

import { showAlert, initiateSearch, sortArrByKey, shouldHavePermission, sortAndFilterArrByKey, changeFormatWithoutTz } from "../../../../../../../Assests/Utility";

import WarehouseSearch from "../../../../../../SuperWarehouse/Components/SearchWarehouse/WarehouseSearch";
import { getUserSelector } from "../../../../../../../../redux/reducers/reducerSlices/UserReducer";

import PoIntakeAssignmentList from "../PoIntakeAssignmentList/PoIntakeAssignmentList";
import PoIntakeAssignmentForm from "../PoIntakeAssignmentForm/PoIntakeAssignmentForm";
import { getPoIntakeMangementSelector } from "../../../../../../../../redux/reducers/reducerSlices/PoIntakeMangementReducer";
import { getCurrentDate } from "../../../../../../../../Utils/DateUtils";
import CustomButtonSS from "../../../../../../Common/CustomButton/CustomButtonSS";

/**
 * Used to get the list of spro warehouse details pass those data as props
 * to the spro warehouse list component 
 * and also perform the CRUD operations for the spro warehouse creation
 * @param {*} props 
 * @returns 
 */
const PoIntakeAssignmentAdminScreen = (props) => {
  const { themeSelected } = props;
  const user = useSelector(getUserSelector);
  const { selectedWarehouse, cu_id } = user;
  // const [loading, setLoading] = useState(false);
  const poIntakeMangementDetails = useSelector(getPoIntakeMangementSelector);
  const { poIntakeList, listLoading, loading, responseObject } = poIntakeMangementDetails
  const [poIntakeListData, setpoIntakeListData] = useState([]);
  const [selectedObj, setSelectedObj] = useState(null)
  const [showDeleteModel, setShowDeleteModel] = useState(false);
  const [currentDeleteIndex, setCurrentDeleteIndex] = useState('NA');



  const [editData, setEditData] = useState({});

  const [searchStr, setSearchStr] = useState('');
  const dispatch = useDispatch();



  useEffect(() => {
    setTimeout(() => {
      fetchMappingList()
    }, 500)

  }, [])

  useEffect(() => {
    if (responseObject) {
      setShowModal(false)
      setShowDeleteModel(false)
      fetchMappingList()
      dispatch({ type: sagaActions.CLEAR_PO_INTAKE_RESPONSE_OBJECT })
    }
  }, [responseObject])

  useEffect(() => {
    if (searchStr.length > 3) {
      const searchedArr = initiateSearch(poIntakeListData, searchStr, 'warehouse');
      setpoIntakeListData(searchedArr);
    } else if (searchStr === '') {
      setpoIntakeListData(poIntakeList)
    }
  }, [searchStr])



  useEffect(() => {
    if (poIntakeList) {
      if (searchStr) {
        const searchedArr = initiateSearch(poIntakeList, searchStr, 'warehouse');
        setpoIntakeListData(searchedArr);
      } else {
        setpoIntakeListData(poIntakeList);
      }
    }
  }, [poIntakeList]);


  const [showModal, setShowModal] = useState(false);
  const closepopup = () => {

    setEditData({});
    setShowModal(false);
  }

  const fetchMappingList = () => {
    let payload = createPayload({}, "S")
    dispatch({ type: sagaActions.GET_PO_INTAKE_MANGEMENT_DETAILS, payload: payload })
  }

  const createPayload = (data, type) => {

    let payload = {}
    if (type === "I") {
      payload = {
        "CRUDTYPE": "I",
        "ID": "",
        "IDWarehouse": data.warehouse && data.warehouse.toUpperCase() != AppConstant.help.poIntakeDockExcelElementName.toUpperCase() ? data.warehouse : null,
        "CompanyID":data.company,
        "IDDock": data.dock && (data.dock.toUpperCase() != AppConstant.help.poIntakeDockExcelElementName.toUpperCase() &&  data.dock.toUpperCase() != AppConstant.help.poIntakeDockExcelRuleValue.toUpperCase()) ? data.dock : null,
        "FileName": data.fileName,
        "FileNameFormat": "xlsx",
        "PONumberMapping": "",
        "PONumberExcel": data.poNumber,
        "PONumberDefault": data.defaultPoNumber,
        "PONumberRule": "",
        "DueDateMapping": "",
        "DueDateExcel": data.dueDate,
        "DueDateDefault": data.defaultDueDate,
        "DueDateRule": "",
        "QuantityMapping": "",
        "QuantityExcel": data.quantity,
        "QuantityDefault": data.defaultQuantity,
        "QuantityRule": "",
        "ItemsCasesMapping": "",
        "ItemsCasesExcel": data.itemsCases,
        "ItemsCasesDefault": data.defaultItemsCases,
        "ItemsCasesRule": "",
        "PalletsMapping": "",
        "PalletsExcel": data.pallets,
        "PalletsDefault": data.defaultPallets,
        "PalletsRule": "",
        "VendorNameMapping": "",
        "VendorNameExcel": data.vendorName,
        "VendorNameDefault": data.defaultVendorName,
        "VendorNameRule": "",
        "CommodityNameMapping": "",
        "CommodityNameExcel": data.commodity,
        "CommodityNameDefault": data.defaultCommodity,
        "CommodityNameRule": "",
        "LoadTypeMapping": "",
        "LoadTypeExcel": data.loadType,
        "LoadTypeDefault": data.defaultLoadType,
        "LoadTypeRule": "",
        "CreatedOn": changeFormatWithoutTz(getCurrentDate(), AppConstant.commonStrings.YYYY_MM_DD_T_HH_mm_ss),
        "CreatedBy": cu_id,
        "UpdatedDate": changeFormatWithoutTz(getCurrentDate(), AppConstant.commonStrings.YYYY_MM_DD_T_HH_mm_ss),
        "UpdatedBy": cu_id,
        "IsActive": 1,
        "IsDeleted": 0,
        "ExcelHeaderRow": data.excelHeaderRow,
        "ExcelDataRow": data.excelDataRow,
        "excelDockName": data.excelDockName ? data.excelDockName : "",
        "DefaultDockName": data.defaultDockName ? data.defaultDockName : "",
        "ExcelWarehouse": data.excelWarehouseName ? data.excelWarehouseName : "",
        "ExcelPORelationID": data?.poRelationID,
        "DefaultPORelationID": data?.defaultPoRelationID,
        "PORelationIDRule":"",
        "POLinkExcel":data?.poLink,
        "POLinkDefault":data?.defaultPoLink,
        "POLinkRule": "",
        "BuyerNameExcel": data?.buyerNameExcel,
        "BuyerNameDefault": data?.buyerNameDefault,
        "DockNameRule": AppConstant.help.poIntakeDockExcelRuleValue.toUpperCase()
      }
    } else if (type === 'U') {
      payload = {
        "CRUDTYPE": "U",
        "ID": data.ID,
        "IDWarehouse": data.warehouse && data.warehouse.toUpperCase() != AppConstant.help.poIntakeDockExcelElementName.toUpperCase() ? data.warehouse : null,
        "CompanyID":data.CompanyID,
        "IDDock":  data.dock && (data.dock.toUpperCase() != AppConstant.help.poIntakeDockExcelElementName.toUpperCase() &&  data.dock.toUpperCase() != AppConstant.help.poIntakeDockExcelRuleValue.toUpperCase()) ? data.dock : null,
        "FileName": data.fileName,
        "FileNameFormat": "xlsx",
        "PONumberMapping": "",
        "PONumberExcel": data.poNumber,
        "PONumberDefault": data.defaultPoNumber,
        "PONumberRule": "",
        "DueDateMapping": "",
        "DueDateExcel": data.dueDate,
        "DueDateDefault": data.defaultDueDate,
        "DueDateRule": "",
        "QuantityMapping": "",
        "QuantityExcel": data.quantity,
        "QuantityDefault": data.defaultQuantity,
        "QuantityRule": "",
        "ItemsCasesMapping": "",
        "ItemsCasesExcel": data.itemsCases,
        "ItemsCasesDefault": data.defaultItemsCases,
        "ItemsCasesRule": "",
        "PalletsMapping": "",
        "PalletsExcel": data.pallets,
        "PalletsDefault": data.defaultPallets,
        "PalletsRule": "",
        "VendorNameMapping": "",
        "VendorNameExcel": data.vendorName,
        "VendorNameDefault": data.defaultVendorName,
        "VendorNameRule": "",
        "CommodityNameMapping": "",
        "CommodityNameExcel": data.CommodityNameExcel,
        "CommodityNameDefault": data.CommodityNameDefault,
        "CommodityNameRule": "",
        "LoadTypeMapping": "",
        "LoadTypeExcel": data.loadType,
        "LoadTypeDefault": data.defaultLoadType,
        "LoadTypeRule": "",
        "CreatedOn": data.CreatedOn,
        "CreatedBy": data.CreatedBy,
        "UpdatedDate": changeFormatWithoutTz(getCurrentDate(), AppConstant.commonStrings.YYYY_MM_DD_T_HH_mm_ss),
        "UpdatedBy": cu_id,
        "IsActive": data.IsActive,
        "IsDeleted": data.IsDeleted,
        "ExcelHeaderRow": data.excelHeaderRow,
        "ExcelDataRow": data.excelDataRow,
        "excelDockName": data.excelDockName ? data.excelDockName : "",
        "DefaultDockName": data.defaultDockName ? data.defaultDockName : "",
        "ExcelWarehouse": data.excelWarehouseName ? data.excelWarehouseName : "",
        "ExcelPORelationID": data.poRelationID,
        "DefaultPORelationID": data.defaultPoRelationID,
        "PORelationIDRule":"",
        "POLinkExcel":data?.poLink,
        "POLinkDefault":data?.defaultPoLink,
        "POLinkRule": "",
        "BuyerNameExcel": data?.buyerNameExcel,
        "BuyerNameDefault": data?.buyerNameDefault,
        "DockNameRule": AppConstant.help.poIntakeDockExcelRuleValue.toUpperCase()
      }
    } else if (type === 'D') {
      payload = {
        "CRUDTYPE": "D",
        "ID": data.ID,
        "IDWarehouse": data.IDWarehouse && data.IDWarehouse.toUpperCase() != AppConstant.help.poIntakeDockExcelElementName.toUpperCase() ? data.IDWarehouse : null,
        "CompanyID":data.CompanyID,
        "IDDock":  data.dock && (data.dock.toUpperCase() != AppConstant.help.poIntakeDockExcelElementName.toUpperCase() &&  data.dock.toUpperCase() != AppConstant.help.poIntakeDockExcelRuleValue.toUpperCase()) ? data.dock : null,
        "FileName": data.FileName,
        "FileNameFormat": "xlsx",
        "PONumberMapping": "",
        "PONumberExcel": data.PONumberExcel,
        "PONumberDefault": data.PONumberDefault,
        "PONumberRule": "",
        "DueDateMapping": "",
        "DueDateExcel": data.DueDateExcel,
        "DueDateDefault": data.DueDateDefault,
        "DueDateRule": "",
        "QuantityMapping": "",
        "QuantityExcel": data.QuantityExcel,
        "QuantityDefault": data.QuantityDefault,
        "QuantityRule": "",
        "ItemsCasesMapping": "",
        "ItemsCasesExcel": data.ItemsCasesExcel,
        "ItemsCasesDefault": data.ItemsCasesDefault,
        "ItemsCasesRule": "",
        "PalletsMapping": "",
        "PalletsExcel": data.PalletsExcel,
        "PalletsDefault": data.PalletsDefault,
        "PalletsRule": "",
        "VendorNameMapping": "",
        "VendorNameExcel": data.VendorNameExcel,
        "VendorNameDefault": data.VendorNameDefault,
        "VendorNameRule": "",
        "CommodityNameMapping": "",
        "CommodityNameExcel": data.CommodityNameExcel,
        "CommodityNameDefault": data.CommodityNameDefault,
        "CommodityNameRule": "",
        "LoadTypeMapping": "",
        "LoadTypeExcel": data.LoadTypeExcel,
        "LoadTypeDefault": data.LoadTypeDefault,
        "LoadTypeRule": "",
        "CreatedOn": data.CreatedOn,
        "CreatedBy": data.CreatedBy,
        "UpdatedDate": changeFormatWithoutTz(getCurrentDate(), AppConstant.commonStrings.YYYY_MM_DD_T_HH_mm_ss),
        "UpdatedBy": cu_id,
        "IsActive": data.IsActive,
        "IsDeleted": 1,
        "ExcelHeaderRow": data.HeaderRow,
        "ExcelDataRow": data.DataRow,
        "excelDockName": data.excelDockName ? data.excelDockName : "",
        "DefaultDockName": data.DefaultDockName ? data.DefaultDockName : "",
        "ExcelWarehouse": data.ExcelWarehouse ? data.ExcelWarehouse : "",
        "ExcelPORelationID": data.ExcelPORelationID,
        "DefaultPORelationID": data.DefaultPORelationID,
        "PORelationIDRule":"",
        "POLinkExcel":data?.POLinkExcel,
        "POLinkDefault":data?.POLinkDefault,
        "POLinkRule": "",
        "BuyerNameExcel": data?.buyerNameExcel,
        "BuyerNameDefault": data?.buyerNameDefault,
        "DockNameRule": data?.dockNameRule
      }
    } else {
      payload = {
        "CRUDTYPE": "S",
        "ID": '',
        "IDWarehouse": "",
        "CompanyID":"",
        "IDDock": "",
        "FileName": "",
        "FileNameFormat": "xlsx",
        "PONumberMapping": "",
        "PONumberExcel": "",
        "PONumberDefault": "",
        "PONumberRule": "",
        "DueDateMapping": "",
        "DueDateExcel": "",
        "DueDateDefault": "",
        "DueDateRule": "",
        "QuantityMapping": "",
        "QuantityExcel": "",
        "QuantityDefault": "",
        "QuantityRule": "",
        "ItemsCasesMapping": "",
        "ItemsCasesExcel": "",
        "ItemsCasesDefault": "",
        "ItemsCasesRule": "",
        "PalletsMapping": "",
        "PalletsExcel": "",
        "PalletsDefault": "",
        "PalletsRule": "",
        "VendorNameMapping": "",
        "VendorNameExcel": "",
        "VendorNameDefault": "",
        "VendorNameRule": "",
        "CommodityNameMapping": "",
        "CommodityNameExcel": "",
        "CommodityNameDefault": "",
        "CommodityNameRule": "",
        "LoadTypeMapping": "",
        "LoadTypeExcel": "",
        "LoadTypeDefault": "",
        "LoadTypeRule": "",
        "CreatedOn": "",
        "CreatedBy": "",
        "UpdatedDate": "",
        "UpdatedBy": "",
        "IsActive": 1,
        "IsDeleted": 1,
        "ExcelHeaderRow": "",
        "ExcelDataRow": "",
        "excelDockName": "",
        "DefaultDockName": "",
        "ExcelWarehouse": "",
        "ExcelPORelationID": "",
        "DefaultPORelationID": "",
        "PORelationIDRule":"",
        "POLinkExcel":"",
        "POLinkDefault":"",
        "POLinkRule": "",
        "BuyerNameExcel": "",
        "BuyerNameDefault": "",
        "DockNameRule": ""
      }
    }
    return payload
  }



  const popupObj = {
    id: "createpointakeFormPopup1",
    modalLabel: "createpointakeFormPopup1",
    showClose: true,
    showfooter: true,
    showHeader: true,
    sendDriverNotification: false,
    modalSize: "xl",
    title: Object.keys(editData).length ? AppConstant.superUserAdmin.poIntakeAssignment.buttonTitles.editPoIntakeAssignmentButtonTitle :
      AppConstant.superUserAdmin.poIntakeAssignment.buttonTitles.createPoIntakeAssignmentButtonTitle,
    bodyText: <PoIntakeAssignmentForm editData={editData} createPayload={(data, type) => createPayload(data, type)} loading={loading} />,
    subText: "",
    keyboard: false,
    backdrop: "static",
    footerBtn: {
      btn1: {
        show: true,
        text: AppConstant.comments.cancelCommentButtonTitle,
      },
      btn2: {
        show: true,
        text: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm
          .saveButtonTitle,
      },
    },
  };

  const changeStatus = (data) => {
    let obj = {
      "ID": data.ID,
      "CompanyID":data.CompanyID,
      "warehouse": data.IDWarehouse && data.IDWarehouse.toUpperCase() != AppConstant.help.poIntakeDockExcelElementName.toUpperCase() ? data.IDWarehouse : null,
      "dock": data.IDDock && data.IDDock.toUpperCase() != AppConstant.help.poIntakeDockExcelElementName.toUpperCase() ? data.IDDock : null,
      "fileName": data.FileName,
      "FileNameFormat": "xlsx",
      "PONumberMapping": "",
      "poNumber": data.PONumberExcel,
      "defaultPoNumber": data.PONumberDefault,
      "PONumberRule": "",
      "DueDateMapping": "",
      "dueDate": data.DueDateExcel,
      "defaultDueDate": data.DueDateDefault,
      "DueDateRule": "",
      "QuantityMapping": "",
      "quantity": data.QuantityExcel,
      "defaultQuantity": data.QuantityDefault,
      "QuantityRule": "",
      "ItemsCasesMapping": "",
      "itemsCases": data.ItemsCasesExcel,
      "defaultItemsCases": data.ItemsCasesDefault,
      "ItemsCasesRule": "",
      "PalletsMapping": "",
      "pallets": data.PalletsExcel,
      "defaultPallets": data.PalletsDefault,
      "PalletsRule": "",
      "VendorNameMapping": "",
      "vendorName": data.VendorNameExcel,
      "defaultVendorName": data.VendorNameDefault,
      "VendorNameRule": "",
      "CommodityNameMapping": "",
      "CommodityNameExcel": data.CommodityNameExcel,
      "CommodityNameDefault": data.CommodityNameDefault,
      "CommodityNameRule": "",
      "LoadTypeMapping": "",
      "loadType": data.LoadTypeExcel,
      "defaultLoadType": data.LoadTypeDefault,
      "CreatedOn": data.CreatedOn,
      "CreatedBy": data.CreatedBy,
      "UpdatedDate": changeFormatWithoutTz(getCurrentDate(), AppConstant.commonStrings.YYYY_MM_DD_T_HH_mm_ss),
      "UpdatedBy": cu_id,
      "IsActive": !data.IsActive,
      "IsDeleted": data.IsDeleted,
      "excelHeaderRow": data.HeaderRow,
      "excelDataRow": data.DataRow,
      "excelDockName": data.excelDockName ? data.excelDockName : "",
      "DefaultDockName": data.defaultDockName ? data.defaultDockName : "",
      "ExcelWarehouse": data.excelWarehouseName ? data.excelWarehouseName : "",
      "poRelationID": data.ExcelPORelationID,
      "defaultPoRelationID": data.DefaultPORelationID,
      "PORelationIDRule":"",
      "poLink":data.POLinkExcel,
      "defaultPoLink":data.POLinkDefault,
      "POLinkRule":""
    }
    let payload = createPayload(obj, "U")
    // let payload = {...data,"CRUDTYPE":"U",dock:data.dock.dockID,warehouse:data.warehouse.warehouseID}
    dispatch({ type: sagaActions.GET_PO_INTAKE_MANGEMENT_DETAILS, payload: payload })
  }

  /**
       * Helper to delete
       * @param {*} data 
       * @param {*} index 
       */
  const deleteHandler = (data) => {
    setSelectedObj(data)
    // setSelectedIndex(index)
    setShowDeleteModel(true)
  }

  /**
  * delete popup handler to delete the records based on the confirmation
  * @param {*} text 
  */
  const popupBtnHandler = (text) => {
    if (text === AppConstant.commonStrings.no) {
      setShowDeleteModel(false)
      setSelectedObj(null)
      // setSelectedIndex(null)
    }
    else {
      // setCurrentDeleteIndex(selectedIndex)
      let payload = createPayload(selectedObj, "D")
      dispatch({ type: sagaActions.GET_PO_INTAKE_MANGEMENT_DETAILS, payload: payload })
    }
    setShowDeleteModel(false)
  }

  /**
  * Helper to show the delete conformation pop-up
  * @returns 
  */
  const showDeleteConfirmation = () => {
    const popupObj = {
      id: 'showDeleteConfirmation',
      modalLabel: 'showDeleteConfirmation',
      showClose: true,
      showfooter: true,
      showHeader: true,
      sendDriverNotification: false,
      modalSize: 'md',
      title: AppConstant.fsWarehouse.deleteTimeslotTitle,
      bodyText: `${AppConstant.commonStrings.areYouSure} ${AppConstant.fsWarehouse.deleteTimeslotTitle}`,
      subText: "",
      keyboard: false,
      backdrop: "static",
      footerBtn: {
        btn1: {
          show: true,
          text: AppConstant.commonStrings.yes
        },
        btn2: {
          show: true,
          text: AppConstant.commonStrings.no
        }
      },
    }

    return <Popup {...popupObj} popupBtnHandler={(text) => { popupBtnHandler(text) }} closepopup={() => { setShowDeleteModel(false) }} themeSelected={themeSelected} showModal={showDeleteModel} />
  }

  const deleteElement = (data) => {
    deleteHandler(data);
    // let payload = createPayload(data, "D"
    // dispatch({ type: sagaActions.GET_PO_INTAKE_MANGEMENT_DETAILS, payload: payload })
  }

  return (
    <div className="pt-2">

      <div className='container-fluid d-flex py-2 text-start justify-content-between'>
        <button
          className={'addButton'}
          data-bs-toggle="modal"
          onClick={() => setShowModal(true)}
        >
          {
            AppConstant.superUserAdmin.poIntakeAssignment.buttonTitles.createPoIntakeAssignmentButtonTitle
          }
        </button>
        <div className="d-flex gap-3">
        <CustomButtonSS
                                    isLoading={false}
                                    title={''}
                                    className={"btn-white f-12 h-100"}
                                    onClick={() => {fetchMappingList()}}
                                    isIcon={true}
                                    iconClass='fa-solid fa-arrows-rotate'
                                />
        <div className='w-100'>
          <WarehouseSearch
            placeholder={'Search...'}
            inputValue={searchStr}
            cancelSearch={() => setSearchStr('')}
            onChangeHandler={(text) => setSearchStr(text)} />
        </div>
        </div>
      </div>
      {showDeleteConfirmation()}
      <div className="roleListTable mt-1">

        <PoIntakeAssignmentList propsObj={props} poIntakeListData={poIntakeListData} editClicked={(data) => { setEditData(data); setShowModal(true) }}
          changeStatus={(el) => { changeStatus(el) }} deleteClicked={(el) => { deleteElement(el) }} themeSelected={themeSelected} loading={listLoading} />
        <Popup
          {...popupObj}
          showModal={showModal}
          showfooter={false}
          closepopup={closepopup}
          themeSelected={themeSelected}

        />
      </div>
    </div>
  );
};

export default PoIntakeAssignmentAdminScreen;
